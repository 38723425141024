import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { configuration } from '../../configuration';
import { RouteUtil } from 'src/app/general/util/route-util';
import { Runnable } from 'src/app/general/interfaces/functions';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
      private router: Router){
  }

  // See com.waiternow.app.web.waiternow.main.frontend.waiternow.ServerMain (RedirectController).
  // RedirectController redirects all paths to the root and includes the original path as query
  // parameter "original_path".
  // When you try to access "/foo/bar" directly, the browser sends HTTP GET request to such
  // URL, causing a page not found error. When you try to access this URL via Angular link
  // clicking, Angular is intercepting this action and does the redirect. Thus, every request
  // has to be redirected to the index file that bootstraps AngularJS application.
  public handleRedirect(activatedRoute: ActivatedRoute, onNotRedirected: Runnable = () => {}): void {
    RouteUtil.getQueryParams(activatedRoute, params => {
      if (params['original_path']) {
        this.router.navigate([params['original_path']], {})
      } else {
        onNotRedirected();
      }
    });
  }

  public goToHomePage(): void {
    this.router.navigate([''], {});
  }

  public goToPricingPage(): void {
    this.router.navigate(['pricing'], {});
  }

  public goToRequirementsPage(): void {
    this.router.navigate(['requirements'], {});
  }

  public goToFoodTruckPage(): void {
    this.router.navigate(['foodtruck'], {});
  }

  public goToRestaurantPage(): void {
    this.router.navigate(['restaurant'], {});
  }

  public goToFacebook(): void {
    window.open(configuration.facebookLink, "_blank");
  }

  public goToInstagram(): void {
    window.open(configuration.instagramLink, "_blank");
  }

  public goToYouTube(): void {
    window.open(configuration.youtubeLink, "_blank");
  }

  public goToTwitter(): void {
    window.open(configuration.twitterLink, "_blank");
  }

  public goToLinkedin(): void {
    window.open(configuration.linkedinLink, "_blank");
  }
}
